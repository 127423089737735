/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .arksoft-text-color {
    color: #23297a;
  }
  .arksoft-bg-color {
    background-color: #23297a;
  }
  .active {
    position: sticky;
    top: 0px;
    background: white;
    border-bottom-width: 2px;
  }
}
